import React, { useEffect, useRef, useState } from "react";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import axios from "axios";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

const AllProducts = () => {
  const dt = useRef(null);

  const [token, setToken] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (token) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/admin/list_all_products/`,
          {
            start_date: "2020-01-01",
            end_date: "2024-12-31",
          },
          {
            headers: {
              "x-staff-user-auth": token,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setAllProducts(res.data.products_posted);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help"
        onClick={exportCSV}
      />
    );
  };

  const header = (
    <div
      className="flex-wrap gap-2 align-items-center justify-content-between"
      style={{ display: "flex" }}>
      <h4 className="m-0">All Products Dashboard</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  return (
    <div className="page">
      {/* Main Navbar*/}
      <Header />

      <div className="page-content d-flex align-items-stretch">
        {/* Side Navbar */}
        <nav className="side-navbar">
          {/* Sidebar Header*/}
          <span className="heading py-3">Main</span>
          {/* Sidebar Navigation Menus*/}
          <ul className="list-unstyled">
            <li>
              <a href="/Dashboard">
                {" "}
                <img
                  src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                  alt="Dashboard"
                  className="px-2"
                />{" "}
                Dashboard{" "}
              </a>
            </li>
            <li>
              <a href="/Profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                  alt="Profile"
                  className="px-2"
                />{" "}
                Profile{" "}
              </a>
            </li>
            <li>
              <a href="/Notification">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                  alt="Notification"
                  className="px-2"
                />{" "}
                Notification{" "}
              </a>
            </li>
            <li>
              <a href="/Forms">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                  alt="update"
                  className="px-2"
                />{" "}
                Update Details{" "}
              </a>
            </li>
            <li>
              <a href="/PaymentDetails">
                {" "}
                <img
                  src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                  alt="Payment Details"
                  className="px-2"
                />{" "}
                Payment Details{" "}
              </a>
            </li>
            <li>
              <a href="/Settings">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                  alt="Settings"
                  className="px-2"
                />{" "}
                Settings{" "}
              </a>
            </li>
          </ul>
        </nav>
        <div className="content-inner">
          {/* TABLES */}
          <section className="tables">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <>
                          <Toolbar
                            className="mb-4"
                            // left={leftToolbarTemplate}
                            right={rightToolbarTemplate}></Toolbar>

                          <DataTable
                            ref={dt}
                            value={allProducts}
                            dataKey="product_uuid"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={globalFilter}
                            header={header}>
                            <Column header="#" body={"#"}></Column>
                            <Column
                              field={"product_name"}
                              header="Product Name"
                              sortable></Column>

                            <Column
                              field={"category.product_category_name"}
                              sortable
                              header="Product Category"></Column>

                            <Column
                              field="days"
                              header="Total Days"
                              sortable></Column>

                            <Column
                              header="Status"
                              body={(rowData) => (
                                <>
                                  {rowData.status === "Active" ? (
                                    <button className="btn btn-success">
                                      {rowData.status}
                                    </button>
                                  ) : (
                                    <button className="btn btn-danger">
                                      {rowData.status}
                                    </button>
                                  )}
                                </>
                              )}></Column>
                          </DataTable>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Page Footer*/}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AllProducts;
