import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";

const CompanyDetails = () => {
  const location = useLocation();
  console.log(location);

  const [token, setToken] = useState(null);
  const [state, setState] = useState([]);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    if (location.state) {
      setState((state) => {
        return { ...state, companyDetails: location.state.id };
      });
    }
  }, [token, location.state]);

  return (
    <div className="page">
      <Header />

      <div className="page-content d-flex align-items-stretch">
        {/* Side Navbar */}
        <nav className="side-navbar">
          {/* Sidebar Header*/}
          <span className="heading py-3">Main</span>
          <ul className="list-unstyled">
            <li>
              <a href="/Dashboard">
                {" "}
                <img
                  src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                  alt="Dashboard"
                  className="px-2"
                />{" "}
                Dashboard{" "}
              </a>
            </li>
            <li>
              <a href="/Profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                  alt="Profile"
                  className="px-2"
                />{" "}
                Profile{" "}
              </a>
            </li>
            <li>
              <a href="/Notification">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                  alt="Notification"
                  className="px-2"
                />{" "}
                Notification{" "}
              </a>
            </li>
            <li>
              <a href="/Forms">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                  alt="update"
                  className="px-2"
                />{" "}
                Update Details{" "}
              </a>
            </li>
            <li>
              <a href="/PaymentDetails">
                {" "}
                <img
                  src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                  alt="Payment Details"
                  className="px-2"
                />{" "}
                Payment Details{" "}
              </a>
            </li>
            <li>
              <a href="/Settings">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                  alt="Settings"
                  className="px-2"
                />{" "}
                Settings{" "}
              </a>
            </li>
          </ul>
        </nav>
        <div className="content-inner">
          {/* Page Header*/}
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="no-margin-bottom">Company Details Dashboard</h2>
            </div>
          </header>
          {/* Updates Section                                                */}
          <section className="updates no-padding-top">
            <div className="container-fluid">
              <div className="row">
                {/* Daily Feeds */}
                <div className="col-lg-12 pt-5">
                  <div className="daily-feeds card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard7"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard7"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <Link to="#." className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </Link>
                          <Link to="#." className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-header">
                      <h3 className="h4">Company Details</h3>
                    </div>
                    <div className="card-body no-padding">
                      {state.companyDetails && (
                        <div className="container py-5">
                          <div className="input-group ">
                            <label
                              htmlFor="fileInput"
                              className="col-sm-3 form-control-label">
                              Company Logo
                            </label>
                            <div className="col-sm-9">
                              <img
                                src={state.companyDetails.company_logo}
                                alt="company logo"
                                height={150}
                                width={250}
                              />
                            </div>
                          </div>
                          <br />

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              Company Name
                            </span>
                            <h5 className="form-control">
                              {state.companyDetails.company_name}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              Background
                            </span>
                            <textarea
                              className="form-control"
                              defaultValue={state.companyDetails.background}
                            />
                          </div>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              Account Type
                            </span>
                            <h5 className="form-control">
                              {state.companyDetails.account_type}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              Address
                            </span>
                            <h5 className="form-control">
                              {state.companyDetails.address},
                              {state.companyDetails.location}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              City
                            </span>
                            <h5 className="form-control">
                              {state.companyDetails.city},{" "}
                              {state.companyDetails.country}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              Email
                            </span>
                            <h5 className="form-control">
                              {state.companyDetails.email}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              GPS
                            </span>
                            <h5 className="form-control">
                              {state.companyDetails.gps}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              Industry
                            </span>
                            <h5 className="form-control">
                              {state.companyDetails.industry.industry_name}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              momo_acct_name
                            </span>
                            <h5 className="form-control">
                              {state.companyDetails.momo_acct_name}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              momo_number
                            </span>
                            <h5 className="form-control">
                              {state.companyDetails.momo_number}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              phone_number
                            </span>
                            <h5 className="form-control">
                              {state.companyDetails.phone_number}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              social_media (facebook)
                            </span>
                            <h5 className="form-control">
                              {state.companyDetails.social_media.facebook}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              social_media ( instagram)
                            </span>
                            <h5 className="form-control">
                              {state.companyDetails.social_media.instagram}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              social_media ( twitter)
                            </span>
                            <h5 className="form-control">
                              {state.companyDetails.social_media.twitter}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              website
                            </span>
                            <h5 className="form-control">
                              {state.companyDetails.website}
                            </h5>
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6">
                            <div className="card card-custom bg-white border-white border-0">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${state.companyDetails.google_map_location}`,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Page Footer*/}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
