import axios from "axios";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import React, { useEffect, useState, useRef } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const AllCompanies = () => {
  const dt = useRef(null);
  const toast = useRef(null);
  const [token, setToken] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/admin/list_companies/`, {
          headers: {
            "x-staff-user-auth": token,
          },
        })
        .then((res) => {
          console.log(res.data);
          setAllCompanies(res.data.companies);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const confirm1 = (id) => {
    console.log(id);
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        accept(id);
      },
      reject,
    });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const accept = (id) => {
    console.log(id);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/admin/delete_company/`,
        {
          company_ids: [id],
        },
        {
          headers: {
            "x-staff-user-auth": token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.current.show({
          severity: "info",
          summary: "Confirmed",
          detail: ` ${res.data.message} `,
          life: 3000,
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 4000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help"
        onClick={exportCSV}
      />
    );
  };

  const header = (
    <div
      className="flex-wrap gap-2 align-items-center justify-content-between"
      style={{ display: "flex" }}>
      <h4 className="m-0">All Companies Dashboard</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />

      <div className="page">
        {/* Main Navbar*/}
        <Header />

        <div className="page-content d-flex align-items-stretch">
          {/* Side Navbar */}
          <nav className="side-navbar">
            {/* Sidebar Header*/}
            <span className="heading py-3">Main</span>
            {/* Sidebar Navigation Menus*/}
            <ul className="list-unstyled">
              <li>
                <a href="/Dashboard">
                  {" "}
                  <img
                    src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                    alt="Dashboard"
                    className="px-2"
                  />{" "}
                  Dashboard{" "}
                </a>
              </li>
              <li>
                <a href="/Profile">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                    alt="Profile"
                    className="px-2"
                  />{" "}
                  Profile{" "}
                </a>
              </li>
              <li>
                <a href="/Notification">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                    alt="Notification"
                    className="px-2"
                  />{" "}
                  Notification{" "}
                </a>
              </li>
              <li>
                <a href="/Forms">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                    alt="update"
                    className="px-2"
                  />{" "}
                  Update Details{" "}
                </a>
              </li>
              <li>
                <a href="/PaymentDetails">
                  {" "}
                  <img
                    src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                    alt="Payment Details"
                    className="px-2"
                  />{" "}
                  Payment Details{" "}
                </a>
              </li>
              <li>
                <a href="/Settings">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                    alt="Settings"
                    className="px-2"
                  />{" "}
                  Settings{" "}
                </a>
              </li>
            </ul>
          </nav>
          <div className="content-inner">
            {/* TABLES */}
            <section className="tables">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <>
                            <Toolbar
                              className="mb-4"
                              // left={leftToolbarTemplate}
                              right={rightToolbarTemplate}></Toolbar>

                            <DataTable
                              ref={dt}
                              value={allCompanies}
                              dataKey="company_uuid"
                              paginator
                              rows={10}
                              rowsPerPageOptions={[5, 10, 25]}
                              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} companies"
                              globalFilter={globalFilter}
                              header={header}>
                              <Column header="#" body={"#"}></Column>
                              <Column
                                field={"company_name"}
                                header="Company Name"
                                sortable></Column>

                              <Column
                                field={"account_type"}
                                sortable
                                header="Account Type "></Column>

                              <Column
                                field="industry.industry_name"
                                header="Industry"
                                sortable></Column>

                              <Column
                                header="Status"
                                sortable
                                body={(rowData) => (
                                  <>
                                    {rowData?.status === "Active" ? (
                                      <button className="btn btn-success">
                                        {rowData?.status}
                                      </button>
                                    ) : (
                                      <button className="btn btn-info">
                                        {rowData?.status}
                                      </button>
                                    )}
                                  </>
                                )}></Column>

                              <Column
                                field="status"
                                header="All Posts"
                                body={(rowData) => (
                                  <Link
                                    to="/all_company_post"
                                    state={{ id: rowData?.company_uuid }}>
                                    <button className="btn btn-primary">
                                      View posts
                                    </button>
                                  </Link>
                                )}></Column>

                              <Column
                                // field="status"
                                header="Action"
                                body={(rowData) => (
                                  <button
                                    onClick={() =>
                                      confirm1(rowData?.company_uuid)
                                    }
                                    className="btn btn-sm btn-danger">
                                    <i className="fa fa-trash-o"> </i>
                                    <span
                                      className="px-2"
                                      style={{ color: "#fff" }}>
                                      Delete
                                    </span>
                                  </button>
                                )}></Column>
                            </DataTable>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer*/}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllCompanies;
