import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";

const ProductDetails = () => {
  const location = useLocation();
  const [state, setState] = useState({
    productDetails: null,
  });

  useEffect(() => {
    if (location.state) {
      setState((state) => {
        return { ...state, productDetails: location.state.id };
      });
    }
  }, [location.state]);

  return (
    <div className="page">
      <Header />

      <div className="page-content d-flex align-items-stretch">
        <nav className="side-navbar">
          {/*End of Side Navbar */}

          <span className="heading">Main</span>
          <ul className="list-unstyled">
            <li>
              <a href="/Dashboard">
                {" "}
                <img
                  src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                  alt="Dashboard"
                  className="px-2"
                />{" "}
                Dashboard{" "}
              </a>
            </li>
            <li>
              <a href="/Profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                  alt="Profile"
                  className="px-2"
                />{" "}
                Profile{" "}
              </a>
            </li>
            <li>
              <a href="/Notification">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                  alt="Notification"
                  className="px-2"
                />{" "}
                Notification{" "}
              </a>
            </li>
            <li>
              <a href="/Forms">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                  alt="update"
                  className="px-2"
                />{" "}
                Update Details{" "}
              </a>
            </li>
            <li>
              <a href="/PaymentDetails">
                {" "}
                <img
                  src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                  alt="Payment Details"
                  className="px-2"
                />{" "}
                Payment Details{" "}
              </a>
            </li>
            <li>
              <a href="/Settings">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                  alt="Settings"
                  className="px-2"
                />{" "}
                Settings{" "}
              </a>
            </li>
          </ul>
        </nav>
        {/*End of Side Navbar */}

        {/* Main Content */}
        <div className="content-inner">
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="no-margin-bottom">
                View Product Details Dashboard
              </h2>
            </div>
          </header>
          <section className="updates no-padding-top">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 pt-5">
                  <div className="daily-feeds card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard7"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard7"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <a href="#/" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </a>
                          <Link to="#" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-header">
                      <h3 className="h4"> Product Details</h3>
                    </div>
                    <div className="card-body no-padding">
                      {state.productDetails && (
                        <div className="container py-5">
                          <div className="input-group ">
                            <label
                              htmlFor="fileInput"
                              className="col-sm-3 form-control-label">
                              Image of Product
                            </label>
                            <div className="col-sm-9">
                              <img
                                src={state.productDetails.image_url}
                                alt="service"
                                height={150}
                                width={250}
                              />
                            </div>
                          </div>
                          <br />
                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Name of Product
                            </span>
                            <h5 className="form-control">
                              {state.productDetails.product_name}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Reference #
                            </span>
                            <h5 className="form-control">
                              {state.productDetails.reference_number}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Price of Product
                            </span>
                            <h5 className="form-control">
                              GH₵ {state.productDetails.price}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Old Price of Product with our charges
                            </span>
                            <h5 className="form-control">
                              GH₵ {state.productDetails.old_price}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Discounted Price of Product with our charges
                            </span>
                            <h5 className="form-control">
                              GH₵ {state.productDetails.new_price}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Seller Amount
                            </span>
                            <h5 className="form-control">
                              GH₵ {state.productDetails.seller_amount}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Coupon Number
                            </span>
                            <h5 className="form-control">
                              {state.productDetails.coupon_number}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">Coupon %</span>
                            <h5 className="form-control">
                              {state.productDetails.coupon_percentage}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              # of days for running ad
                            </span>
                            <h5 className="form-control">
                              {state.productDetails.days}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Date ad was created
                            </span>
                            <h5 className="form-control">
                              {state.productDetails.date_created}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              when ad starts
                            </span>
                            <h5 className="form-control">
                              {state.productDetails.starting_date}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              % of discount
                            </span>
                            <h5 className="form-control">
                              {state.productDetails.discount}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Return Duration (How many days does it take for an
                              item to be returned)
                            </span>
                            <h5 className="form-control">
                              {state.productDetails.return_duration}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">Disclaimer</span>
                            <textarea
                              className="form-control"
                              defaultValue={state.productDetails.disclaimer}
                            />
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Items in Stock
                            </span>
                            <h5 className="form-control">
                              {state.productDetails.stock_number}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Description of Product
                            </span>
                            <textarea
                              className="form-control"
                              defaultValue={state.productDetails.description}
                            />
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Product type
                            </span>
                            <h5 className="form-control">
                              {state.productDetails.product_type}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Product Category
                            </span>
                            <h5 className="form-control">
                              {
                                state.productDetails.sub_category
                                  .product_category_name
                              }
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Product Sub-Category
                            </span>
                            <h5 className="form-control">
                              {
                                state.productDetails.sub_category
                                  .sub_category_name
                              }
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Delivery Available
                            </span>
                            <h5 className="form-control">
                              {state.productDetails.delivery}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Region of Location
                            </span>
                            <h5 className="form-control">
                              {state.productDetails.region.name}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Is product Returnable
                            </span>
                            <h5 className="form-control">
                              {state.productDetails.returnable}
                            </h5>
                          </div>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Delivery Available
                            </span>
                            <h5 className="form-control">
                              {state.productDetails.delivery}
                            </h5>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Page Footer*/}
          <Footer />
        </div>
        {/*End of Main Content */}
      </div>
    </div>
  );
};

export default ProductDetails;
