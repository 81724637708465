import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Header.css";

import { Link } from "react-router-dom";

const Header = () => {
  const [token, setToken] = useState(null);

  const endSession = () => {
    sessionStorage.clear();
  };

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/admin/admin_dashboard/`, {
          headers: {
            "x-staff-user-auth": token,
          },
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            sessionStorage.clear();
            window.location.replace("/");

            // toast.warn("Session expired. Please login again");
            // setTimeout(() => {
            //   sessionStorage.clear();
            //   window.location.replace("/");
            // }, 5000);
          }
        });
    }
  }, [token]);

  return (
    <header className="header">
      <nav className="navbar">
        {/* Search Box*/}
        <div className="search-box">
          <button className="dismiss">
            <i className="icon-close" />
          </button>
          <form id="searchForm" action="#/" role="search">
            <input
              type="search"
              placeholder="What are you looking for..."
              className="form-control"
            />
          </form>
        </div>

        <div className="container-fluid">
          <div className="navbar-holder d-flex align-items-center justify-content-between">
            {/* Navbar Header*/}
            <div className="navbar-header">
              {/* Navbar Brand */}
              <a href="#/" className="navbar-brand d-none d-sm-inline-block">
                <div className="brand-text d-none d-lg-inline-block">
                  <span> </span>
                  <strong>Rosmall Admin</strong>
                </div>
                <div className="brand-text d-none d-sm-inline-block d-lg-none">
                  <strong>Rosmall Admin</strong>
                </div>
              </a>
              {/* Toggle Button*/}
              <a id="toggle-btn" href="#/" className="menu-btn active">
                <span />
                <span />
                <span />
              </a>
            </div>
            {/* Navbar Menu */}
            <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
              {/* Payment dropdown    */}
              <li className="nav-item dropdown">
                <Link
                  id="languages"
                  rel="nofollow"
                  data-target="#"
                  to="#/"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="nav-link language dropdown-toggle">
                  <span className="d-none d-sm-inline-block">Payment</span>
                </Link>
                <ul aria-labelledby="languages" className="dropdown-menu">
                  <li>
                    <a
                      href="/list_paid_items_pending_payout"
                      className="dropdown-item">
                      List Paid Items Pending Payout
                    </a>
                  </li>
                  <li>
                    <a href="/list_paid_items" className="dropdown-item">
                      List Paid Items
                    </a>
                  </li>
                </ul>
              </li>

              {/* Companies dropdown    */}
              <li className="nav-item dropdown">
                <Link
                  id="languages"
                  rel="nofollow"
                  data-target="#"
                  to="#/"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="nav-link language dropdown-toggle">
                  <span className="d-none d-sm-inline-block">Companies</span>
                </Link>
                <ul aria-labelledby="languages" className="dropdown-menu">
                  <li>
                    <a href="/all_companies" className="dropdown-item">
                      List All Companies
                    </a>
                  </li>
                  <li>
                    <a href="/unapproved_companies" className="dropdown-item">
                      Unapproved Companies
                    </a>
                  </li>
                  <li>
                    <a href="/approved_companies" className="dropdown-item">
                      Approved Companies
                    </a>
                  </li>
                </ul>
              </li>

              {/* Products dropdown    */}
              <li className="nav-item dropdown">
                <Link
                  id="languages"
                  rel="nofollow"
                  data-target="#"
                  to="#/"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="nav-link language dropdown-toggle">
                  <span className="d-none d-sm-inline-block">Products</span>
                </Link>
                <ul aria-labelledby="languages" className="dropdown-menu">
                  <li>
                    <a href="/all_products" className="dropdown-item">
                      All Product
                    </a>
                  </li>

                  <li>
                    <a href="/active_products" className="dropdown-item">
                      Active Product
                    </a>
                  </li>

                  <li>
                    <a href="/inactive_products" className="dropdown-item">
                      Inactive Products
                    </a>
                  </li>

                  <li>
                    <a
                      href="/pushed_inactive_products"
                      className="dropdown-item">
                      Pushed Inactive Products
                    </a>
                  </li>

                  <li>
                    <a
                      href="/inactive_boosted_products"
                      className="dropdown-item">
                      Inactive Boosted Products
                    </a>
                  </li>

                  <li>
                    <a
                      href="/active_boosted_products"
                      className="dropdown-item">
                      Active Boosted Products
                    </a>
                  </li>
                </ul>
              </li>

              {/* Languages dropdown    */}
              <li className="nav-item dropdown">
                <Link
                  id="languages"
                  rel="nofollow"
                  data-target="#"
                  to="#/"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="nav-link language dropdown-toggle">
                  <span className="d-none d-sm-inline-block">Services</span>
                </Link>
                <ul aria-labelledby="languages" className="dropdown-menu">
                  <li>
                    <a href="/active_services" className="dropdown-item">
                      Active Service{" "}
                    </a>
                  </li>

                  <li>
                    <a href="/inactive_services" className="dropdown-item">
                      Inactive Services{" "}
                    </a>
                  </li>

                  <li>
                    <a
                      href="/pushed_inactive_services"
                      className="dropdown-item">
                      Pushed Inactive Services{" "}
                    </a>
                  </li>
                </ul>
              </li>

              {/* Shoppers dropdown    */}
              <li className="nav-item dropdown">
                <Link
                  id="languages"
                  rel="nofollow"
                  data-target="#"
                  to="#/"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="nav-link language dropdown-toggle">
                  <span className="d-none d-sm-inline-block">Shoppers</span>
                </Link>
                <ul aria-labelledby="languages" className="dropdown-menu">
                  <li>
                    <a href="/all_shoppers" className="dropdown-item">
                      All Shoppers
                    </a>
                  </li>
                </ul>
              </li>

              {/* Logout    */}
              <li className="nav-item">
                <a href="/" className="nav-link logout" onClick={endSession}>
                  {" "}
                  <span className="d-none d-sm-inline">Logout</span>
                  <i className="fa fa-sign-out" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
