import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Pages/Dashboard/Home";
import Notification from "./Pages/Notifications/Notification";
import NotificationDetails from "./Pages/Notifications/Notification-details";
import Login from "./Pages/Login/Login";
import Settings from "./Pages/Settings/Settings";
import ActiveProducts from "./Pages/Management/Product/ActiveProducts";
import ForgotPassword from "./Pages/Password/ForgotPassword";
import ErrorPage from "./Pages/404/404";
import DraftedServices from "./Pages/Management/Service/InactiveServices";
import ActiveServices from "./Pages/Management/Service/ActiveServices";
import DraftedServiceEdit from "./Pages/Management/Service/ServiceDetails";
import UnapprovedCompanies from "./Pages/Management/Companies/UnapprovedCompanies";
import AllCompanies from "./Pages/Management/Companies/AllCompanies";
import ApprovedCompanies from "./Pages/Management/Companies/ApprovedCompanies";
import AllShoppers from "./Pages/Management/Shoppers/AllShoppers";
import InactiveProduct from "./Pages/Management/Product/InactiveProduct";
import InactiveServices from "./Pages/Management/Service/InactiveServices";
import CompanyDetails from "./Pages/Management/Companies/CompanyDetails";
import AllProducts from "./Pages/Management/Product/AllProducts";
import ProductDetails from "./Pages/Management/Product/ProductDetails";
import AllPost from "./Pages/Management/Companies/AllPost";
import ServiceDetails from "./Pages/Management/Service/ServiceDetails";
import BoostedProducts from "./Pages/Management/Product/ActiveBoostedProducts";
import InactiveBoostedProducts from "./Pages/Management/Product/InactiveBoostedProducts";
import PushedProduct from "./Pages/Management/Product/PushedProduct";
import PushedServices from "./Pages/Management/Service/PushedServices";
import ProtectedRoute from "./ProtectedRoute";
import PaymentDetails from "./Pages/Management/Payment/PaymentDetails";
import ListPaidItems from "./Pages/Management/Payment/List_Paid_Items";
import ListPaidItemsPendingPayout from "./Pages/Management/Payment/List_Paid_Items_Pending_Payout";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />

        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Home />} />

          <Route
            path="/unapproved_companies"
            element={<UnapprovedCompanies />}
          />
          <Route path="/approved_companies" element={<ApprovedCompanies />} />
          <Route path="/all_companies" element={<AllCompanies />} />
          <Route path="/company_details" element={<CompanyDetails />} />
          <Route path="/all_company_post" element={<AllPost />} />

          <Route path="/all_products" element={<AllProducts />} />
          <Route path="/active_products" element={<ActiveProducts />} />
          <Route path="/product_details" element={<ProductDetails />} />
          <Route path="/inactive_products" element={<InactiveProduct />} />
          <Route path="/pushed_inactive_products" element={<PushedProduct />} />
          <Route
            path="/active_boosted_products"
            element={<BoostedProducts />}
          />
          <Route
            path="/inactive_boosted_products"
            element={<InactiveBoostedProducts />}
          />

          <Route path="/active_services" element={<ActiveServices />} />
          <Route path="/service_details" element={<ServiceDetails />} />
          {/* <Route path="/service_details" element={<ServiceDetails />} /> */}
          <Route path="/inactive_services" element={<InactiveServices />} />
          <Route
            path="/pushed_inactive_services"
            element={<PushedServices />}
          />
          <Route path="/DraftedServiceEdit" element={<DraftedServiceEdit />} />
          <Route path="/DraftedServices" element={<DraftedServices />} />

          <Route
            path="/NotificationDetails"
            element={<NotificationDetails />}
          />
          <Route path="/Notification" element={<Notification />} />
          <Route path="/Settings" element={<Settings />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/PaymentDetails" element={<PaymentDetails />} />
          <Route path="/all_shoppers" element={<AllShoppers />} />

          <Route path="/payment_details" element={<PaymentDetails />} />
          <Route path="/list_paid_items" element={<ListPaidItems />} />
          <Route
            path="/list_paid_items_pending_payout"
            element={<ListPaidItemsPendingPayout />}
          />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
