import axios from "axios";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ProductChart from "../../Components/Charts/ProductChart";
import ServicesChart from "../../Components/Charts/ServicesChart";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardInfo } from "../../Redux/slice/adminSlice";

const Home = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const { dashboard_info } = useSelector((state) => state.admin);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/admin/admin_dashboard/`, {
          headers: {
            "x-staff-user-auth": token,
          },
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data.status === 200) {
            // the below line is the one that updates the state
            dispatch(setDashboardInfo(res.data.sysadmin_dashboard));
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // axios
      //   .get(`${process.env.REACT_APP_Base_url}/admin/graph_product_service/`, {
      //     headers: {
      //       "x-staff-user-auth": token,
      //     },
      //   })
      //   .then((res) => {
      //     console.log(res.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    }
  }, [token, dispatch]);

  return (
    <div className="page">
      {/* Header*/}
      <Header />
      {/* End of Header*/}

      <div className="page-content d-flex align-items-stretch">
        {/* Side Navbar */}
        <nav className="side-navbar">
          {/* Sidebar Navigation Menus*/}
          <span className="heading">Main</span>
          <ul className="list-unstyled">
            <li className="active">
              <a href="/Dashboard">
                {" "}
                <img
                  src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                  alt="Dashboard"
                  className="px-2"
                />{" "}
                Dashboard{" "}
              </a>
            </li>

            <li>
              <a href="/Notification">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                  alt="Notification"
                  className="px-2"
                />{" "}
                Notification{" "}
              </a>
            </li>

            <li>
              <a href="/PaymentDetails">
                {" "}
                <img
                  src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                  alt="Payment Details"
                  className="px-2"
                />{" "}
                Payment Details{" "}
              </a>
            </li>
            <li>
              <a href="/Settings">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                  alt="Settings"
                  className="px-2"
                />{" "}
                Settings{" "}
              </a>
            </li>
          </ul>
        </nav>
        <div className="content-inner">
          {/* Page Header*/}
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="no-margin-bottom">Dashboard</h2>
            </div>
          </header>
          {/* Breadcrumb*/}
          <div className="breadcrumb-holder container-fluid">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to="/Dashboard">Home</NavLink>
              </li>
            </ul>
          </div>
          {/* Dashboard Counts Section*/}
          <section className="dashboard-counts no-padding-bottom">
            <div className="container-fluid">
              <div className="row bg-white has-shadow">
                {/* Item */}
                <div className="col-xl-3 col-sm-6">
                  {dashboard_info && (
                    <div className="item d-flex align-items-center">
                      <div className="icon bg-violet">
                        <i className="icon-grid" />
                      </div>
                      <div className="title">
                        <span>
                          Active
                          <br />
                          Products
                        </span>
                        <div className="progress">
                          <div
                            role="progressbar"
                            style={{ width: "100%", height: "4px" }}
                            aria-valuenow={90}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            className="progress-bar bg-violet"
                          />
                        </div>
                      </div>
                      <div className="number">
                        <strong>{dashboard_info.active_products}</strong>
                      </div>
                    </div>
                  )}
                </div>
                {/* Item */}
                {/* Item */}
                <div className="col-xl-3 col-sm-6">
                  {dashboard_info && (
                    <div className="item d-flex align-items-center">
                      <div className="icon bg-blue">
                        <i className="icon-grid" />
                      </div>
                      <div className="title">
                        <span>
                          Active
                          <br />
                          Services
                        </span>
                        <div className="progress">
                          <div
                            role="progressbar"
                            style={{ width: "100%", height: "4px" }}
                            aria-valuenow={90}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            className="progress-bar bg-blue"
                          />
                        </div>
                      </div>
                      <div className="number">
                        <strong>{dashboard_info.active_services}</strong>
                      </div>
                    </div>
                  )}
                </div>
                {/* Item */}

                {/* Item */}
                <div className="col-xl-3 col-sm-6">
                  {dashboard_info && (
                    <div className="item d-flex align-items-center">
                      <div className="icon bg-green">
                        <i className="icon-bill" />
                      </div>
                      <div className="title">
                        <span>
                          Total
                          <br />
                          Companies
                        </span>
                        <div className="progress">
                          <div
                            role="progressbar"
                            style={{ width: "100%", height: "4px" }}
                            aria-valuenow={40}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            className="progress-bar bg-green"
                          />
                        </div>
                      </div>
                      <div className="number">
                        <strong>{dashboard_info.total_companies}</strong>
                      </div>
                    </div>
                  )}
                </div>
                {/* Item */}
                <div className="col-xl-3 col-sm-6">
                  {dashboard_info && (
                    <div className="item d-flex align-items-center">
                      <div className="icon bg-orange">
                        <i className="icon-check" />
                      </div>
                      <div className="title">
                        <span>
                          Total
                          <br />
                          Shoppers
                        </span>
                        <div className="progress">
                          <div
                            role="progressbar"
                            style={{ width: "100%", height: "4px" }}
                            aria-valuenow={dashboard_info.retired_products}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            className="progress-bar bg-orange"
                          />
                        </div>
                      </div>
                      <div className="number">
                        <strong>{dashboard_info.total_shoppers}</strong>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          {/* Charts Section*/}
          <section className="charts">
            <div className="container-fluid">
              <div className="row">
                {/* Line Charts*/}
                {/* <div className="col-lg-12">
                  <div className="line-chart-example card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard1"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <a href="#/" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </a>
                          <a href="#/" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-header d-flex align-items-center">
                      <h3 className="h4">Shoppers Analytics</h3>
                    </div>
                    <div className="card-body">
                      <ProductChart />
                    </div>
                  </div>
                </div> */}

                {/* Line Charts*/}
                {/* <div className="col-lg-12">
                  <div className="line-chart-example card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard1"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <a href="#/" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </a>
                          <a href="#/" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-header d-flex align-items-center">
                      <h3 className="h4">Services Posts Analytics</h3>
                    </div>
                    <div className="card-body">
                      <ServicesChart />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
          {/* Updates Section    */}

          {/*Products Charts Section*/}
          <section className="charts">
            <div className="container-fluid">
              <div className="row">
                {/* Line Charts*/}
                <div className="col-lg-12">
                  <div className="line-chart-example card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard1"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <a href="#/" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </a>
                          <a href="#/" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-header d-flex align-items-center">
                      <h3 className="h4">Products Posts Analytics</h3>
                    </div>
                    <div className="card-body">
                      <ProductChart />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Page Footer*/}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
