import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import axios from "axios";

const Settings = () => {
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(sessionStorage.getItem("token"));
  }, [token]);

  const settingsHandler = (e) => {
    e.preventDefault(); // Prevent
    if (new_password !== confirmPassword) {
      alert("Passwords don't match");
    } else {
      // make API call
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/company/change_password/`,
          {
            new_password,
            old_password,
          },
          {
            headers: {
              "X-COMPANY-USER-AUTH": token,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            alert(res.data.message);
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const passheader = <h6>Pick a password</h6>;
  const passfooter = (
    <React.Fragment>
      <Divider />
      <p className="p-mt-2">Suggestions</p>
      <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: "1.5" }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </React.Fragment>
  );

  return (
    <div>
      <div className="page">
        <Header />

        <div className="page-content d-flex align-items-stretch">
          {/* Side Navbar */}
          <nav className="side-navbar">
            {/* Sidebar Navigation Menus*/}
            <span className="heading">Main</span>
            <ul className="list-unstyled">
              <li>
                <a href="/Dashboard">
                  {" "}
                  <img
                    src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                    alt="Dashboard"
                    className="px-2"
                  />{" "}
                  Dashboard{" "}
                </a>
              </li>
              <li>
                <a href="/Profile">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                    alt="Profile"
                    className="px-2"
                  />{" "}
                  Profile{" "}
                </a>
              </li>
              <li>
                <a href="/Notification">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                    alt="Notification"
                    className="px-2"
                  />{" "}
                  Notification{" "}
                </a>
              </li>
              <li>
                <a href="/Forms">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                    alt="update"
                    className="px-2"
                  />{" "}
                  Update Details{" "}
                </a>
              </li>
              <li>
                <a href="/PaymentDetails">
                  {" "}
                  <img
                    src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                    alt="Payment Details"
                    className="px-2"
                  />{" "}
                  Payment Details{" "}
                </a>
              </li>
              <li className="active">
                <a href="/Settings">
                  {" "}
                  <img
                    src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                    alt="Settings"
                    className="px-2"
                  />{" "}
                  Settings{" "}
                </a>
              </li>
            </ul>
          </nav>
          <div className="content-inner">
            {/* Page Header*/}
            <header className="page-header">
              <div className="container-fluid">
                <h2 className="no-margin-bottom">Change Password</h2>
              </div>
            </header>
            {/* Breadcrumb*/}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/Dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Change Password</li>
              </ul>
            </div>
            {/* Forms Section*/}
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-close">
                        <div className="dropdown">
                          <button
                            type="button"
                            id="closeCard5"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="dropdown-toggle">
                            <i className="fa fa-ellipsis-v" />
                          </button>
                          <div
                            aria-labelledby="closeCard5"
                            className="dropdown-menu dropdown-menu-right has-shadow">
                            <a href="#/" className="dropdown-item remove">
                              {" "}
                              <i className="fa fa-times" />
                              Close
                            </a>
                            <a href="#/" className="dropdown-item edit">
                              {" "}
                              <i className="fa fa-gear" />
                              Edit
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="card-header d-flex align-items-center">
                        <h3 className="h4">Change Password Details</h3>
                      </div>

                      <div className="card-body">
                        <form
                          className="form-horizontal"
                          onSubmit={settingsHandler}>
                          <div className="card">
                            <div className="card-body">
                              <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col">
                                  <h5>Current Password</h5>
                                  <Password
                                    required
                                    value={old_password}
                                    onChange={(e) =>
                                      setOldPassword(e.target.value)
                                    }
                                    toggleMask
                                    header={passheader}
                                    footer={passfooter}
                                  />
                                </div>
                                <br />
                                <div className="p-field p-col">
                                  <h5>New Password</h5>
                                  <Password
                                    required
                                    value={new_password}
                                    onChange={(e) =>
                                      setNewPassword(e.target.value)
                                    }
                                    toggleMask
                                    header={passheader}
                                    footer={passfooter}
                                  />
                                </div>
                                <br />
                                <div className="p-field p-col">
                                  <h5>Confirm Password</h5>
                                  <Password
                                    required
                                    value={confirmPassword}
                                    onChange={(e) =>
                                      setConfirmPassword(e.target.value)
                                    }
                                    toggleMask
                                  />
                                </div>
                                <br />
                                <div className="p-field p-col">
                                  <Button label="Submit" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer*/}
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
