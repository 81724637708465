import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import ReactPagination from "react-paginate";
import axios from "axios";

const ActiveServices = () => {
  const [token, setToken] = useState(null);
  const [active, setActive] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/admin/list_active_services/`, {
          headers: {
            "x-staff-user-auth": token,
          },
        })
        .then((res) => {
          console.log(res.data);
          setActive(res.data.services);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const Deactivate = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/admin/activate_service/`,
        {
          service_id: id,
          flag: "Deactivate",
        },
        {
          headers: {
            "x-staff-user-auth": token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        alert(res.data.message);
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const usersPerPage = 16;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = active ? Math.ceil(active.length / usersPerPage) : 0;

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="page">
      {/* Main Navbar*/}
      <Header />

      <div className="page-content d-flex align-items-stretch">
        {/* Side Navbar */}
        <nav className="side-navbar">
          {/* Sidebar Header*/}
          <span className="heading py-3">Main</span>
          {/* Sidebar Navigation Menus*/}
          <ul className="list-unstyled">
            <li>
              <a href="/Dashboard">
                {" "}
                <img
                  src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                  alt="Dashboard"
                  className="px-2"
                />{" "}
                Dashboard{" "}
              </a>
            </li>
            <li>
              <a href="/Profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                  alt="Profile"
                  className="px-2"
                />{" "}
                Profile{" "}
              </a>
            </li>
            <li>
              <a href="/Notification">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                  alt="Notification"
                  className="px-2"
                />{" "}
                Notification{" "}
              </a>
            </li>
            <li>
              <a href="/Forms">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                  alt="update"
                  className="px-2"
                />{" "}
                Update Details{" "}
              </a>
            </li>
            <li>
              <a href="/PaymentDetails">
                {" "}
                <img
                  src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                  alt="Payment Details"
                  className="px-2"
                />{" "}
                Payment Details{" "}
              </a>
            </li>
            <li>
              <a href="/Settings">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                  alt="Settings"
                  className="px-2"
                />{" "}
                Settings{" "}
              </a>
            </li>
          </ul>
        </nav>
        <div className="content-inner">
          {/* Page Header*/}
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="no-margin-bottom">Active Dashboard</h2>
            </div>
          </header>
          {/* TABLES */}
          <section className="tables">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard3"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard3"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <Link to="#" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </Link>
                          <Link to="#" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-header d-flex align-items-center">
                      <h3 className="h4">Active Service Post </h3>
                    </div>
                    <div className="card-body">
                      {active && (
                        <div className="">
                          Total Active Service : {active.length}
                        </div>
                      )}
                      <div className="table-responsive">
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Service Name</th>
                              <th>Service Category</th>
                              <th>Reference Number</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          {active.length !== 0 &&
                          active.length !== undefined ? (
                            <tbody>
                              {active &&
                                active
                                  .slice(
                                    pagesVisited,
                                    pagesVisited + usersPerPage
                                  )
                                  .map((service) => (
                                    <tr key={service.service_uuid}>
                                      <th scope="row">#</th>
                                      <td>{service.service_name}</td>
                                      <td>
                                        {
                                          service.service_sub_category
                                            .service_category_name
                                        }
                                      </td>
                                      <td>{service.reference_number}</td>
                                      <td>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            Deactivate(service.service_uuid)
                                          }
                                          className="btn btn-danger">
                                          Deactivate
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <th></th>
                                <td></td>
                                <td className="py-3">No Records Found</td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>

                    {active && (
                      <div className="container">
                        <ReactPagination
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          breakLabel={"..."}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination justify-content-end"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Page Footer*/}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ActiveServices;
