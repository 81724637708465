import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ReactPagination from "react-paginate";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import axios from "axios";

const AllPost = () => {
  const location = useLocation();
  console.log(location);
  const [token, setToken] = useState(null);
  const [inactiveProduct, setInactiveProduct] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [id, setId] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (location.state) {
      setId(location.state.id);
    }
  }, [location.state]);

  useEffect(() => {
    if (id && token) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/admin/list_company_posts/`,
          {
            company_uuid: id,
          },
          {
            headers: {
              "x-staff-user-auth": token,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setInactiveProduct(res.data.product_post);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, token]);

  const Activate = (id) => {
    console.log(id);
    // convert the id to array
    const idArray = [];
    idArray.push(id);

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/admin/activate_product/`,
        {
          post_id: idArray,
          flag: "Activate",
        },
        {
          headers: {
            "x-staff-user-auth": token,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          alert(response.data.message);
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Deactivate = (id) => {
    console.log(id);
    // convert the id to array
    const idArray = [];
    idArray.push(id);

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/admin/activate_product/`,
        {
          post_ids: idArray,
          flag: "Deactivate",
        },
        {
          headers: {
            "x-staff-user-auth": token,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          alert(response.data.message);
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Delete = (id) => {
    console.log(id);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/admin/delete_products/`,
        {
          product_ids: [id],
        },
        {
          headers: {
            "x-staff-user-auth": token,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          alert(response.data.message);
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const usersPerPage = 16;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = inactiveProduct
    ? Math.ceil(inactiveProduct.length / usersPerPage)
    : 0;

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="page">
      {/* Main Navbar*/}
      <Header />
      <div className="page-content d-flex align-items-stretch">
        {/* Side Navbar */}
        <nav className="side-navbar">
          {/* Sidebar Header*/}

          {/* Sidebar Navigation Menus*/}
          <span className="heading py-3">Main</span>
          <ul className="list-unstyled">
            <li>
              <a href="/Dashboard">
                {" "}
                <img
                  src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                  alt="Dashboard"
                  className="px-2"
                />{" "}
                Dashboard{" "}
              </a>
            </li>
            <li>
              <a href="/Profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                  alt="Profile"
                  className="px-2"
                />{" "}
                Profile{" "}
              </a>
            </li>
            <li>
              <a href="/Notification">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                  alt="Notification"
                  className="px-2"
                />{" "}
                Notification{" "}
              </a>
            </li>
            <li>
              <a href="/Forms">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                  alt="update"
                  className="px-2"
                />{" "}
                Update Details{" "}
              </a>
            </li>
            <li>
              <a href="/PaymentDetails">
                {" "}
                <img
                  src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                  alt="Payment Details"
                  className="px-2"
                />{" "}
                Payment Details{" "}
              </a>
            </li>
            <li>
              <a href="/Settings">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                  alt="Settings"
                  className="px-2"
                />{" "}
                Settings{" "}
              </a>
            </li>
          </ul>
        </nav>
        <div className="content-inner">
          {/* Page Header*/}
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="no-margin-bottom">All Company Post Dashboard</h2>
            </div>
          </header>
          {/* Updates Section     */}
          <section className="updates no-padding-top">
            <div className="container-fluid">
              <div className="row">
                {/* Daily Feeds */}
                <div className="col-lg-12 pt-5">
                  <div className="daily-feeds card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard7"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard7"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <a href="#/" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </a>
                          <a href="#/" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-header">
                      <h3 className="h4">All Company Post</h3>
                    </div>
                    <div className="card-body no-padding">
                      {/* Item*/}
                      {inactiveProduct.length !== 0 &&
                      inactiveProduct.length !== undefined ? (
                        inactiveProduct &&
                        inactiveProduct
                          .slice(pagesVisited, pagesVisited + usersPerPage)
                          .map((product) => {
                            return (
                              <div className="item" key={product?.product_uuid}>
                                <div className="feed d-flex justify-content-between">
                                  <div className="feed-body d-flex justify-content-between">
                                    <a href="#/" className="feed-profile">
                                      <img
                                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                        alt="person"
                                        className="img-fluid rounded-circle"
                                      />
                                    </a>
                                    <div className="content">
                                      <h5>{product?.product_name}</h5>
                                      <span>{product?.product_type}</span>
                                      <div className="ml-auto">
                                        <span className="mr-2">
                                          status :{" "}
                                          <span className="badge badge-info text-white p-1">
                                            {product?.status}
                                          </span>
                                        </span>
                                      </div>
                                      <div className="CTAs">
                                        {product?.status === "Active" ? (
                                          <button
                                            onClick={() =>
                                              Deactivate(product?.product_uuid)
                                            }
                                            className="btn btn-sm btn-info">
                                            Deactivate
                                          </button>
                                        ) : (
                                          <button
                                            onClick={() =>
                                              Activate(product?.product_uuid)
                                            }
                                            className="btn btn-sm btn-success">
                                            Activate
                                          </button>
                                        )}
                                        <Link
                                          to="/product_details"
                                          state={{ id: product }}
                                          className="btn btn-xs btn-warning m-1 p-1">
                                          <i className="fa fa-pencil-square-o">
                                            {" "}
                                          </i>
                                          View Details
                                        </Link>
                                        <button
                                          onClick={() =>
                                            Delete(product?.product_uuid)
                                          }
                                          className="btn btn-sm btn-danger">
                                          <i className="fa fa-trash-o"> </i>
                                          <span
                                            className="px-2"
                                            style={{ color: "#fff" }}>
                                            Delete
                                          </span>{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="date text-right">
                                    <small>{product?.date_created}</small>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                      ) : (
                        <div className="text-center pt-4">
                          <p>No records found</p>
                        </div>
                      )}
                    </div>

                    {inactiveProduct && (
                      <div className="container">
                        <ReactPagination
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          breakLabel={"..."}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination justify-content-end"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Page Footer*/}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AllPost;
