import axios from "axios";
import { Chart } from "primereact/chart";
import React, { useState, useEffect } from "react";

const ProductChart = () => {
  const [state, setState] = useState({
    graphDetails: null,
    graphLabels: [],
    graphVals: [],
  });
  const [token, setToken] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
  }, []);

  //   get graph data from the DB
  useEffect(() => {
    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/admin/graph_product_service/`, {
          headers: {
            "x-staff-user-auth": token,
          },
        })
        .then((res) => {
          console.log(res.data);
          setState((state) => {
            return { ...state, graphDetails: res.data.graph_products };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  useEffect(() => {
    if (state.graphDetails) {
      state.graphDetails.forEach((element) => {
        state.graphLabels.push(element.month_name);
        state.graphVals.push(element.total_products);
      });
    }
  }, [state.graphDetails, state.graphLabels, state.graphVals]);

  const basicData = {
    labels: state.graphLabels,
    datasets: [
      {
        label: "Total Products posted",
        backgroundColor: "#FFA726",
        data: state.graphVals,
      },
    ],
  };

  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 1.0,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };

    return {
      basicOptions,
    };
  };

  const { basicOptions } = getLightTheme();

  return (
    <div>
      <div className="card">
        <Chart type="bar" data={basicData} options={basicOptions} />
      </div>
    </div>
  );
};

export default ProductChart;
