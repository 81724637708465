import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // get token details
    const tk = sessionStorage.getItem("token");
    if (tk) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/company/get_company_reviews/`, {
          headers: {
            "X-COMPANY-USER-AUTH": tk,
          },
        })
        .then((res) => {
          setNotifications(res.data.review_rating);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <div className="page">
      {/* Main Navbar*/}
      <Header />
      <div className="page-content d-flex align-items-stretch">
        {/* Side Navbar */}
        <nav className="side-navbar">
          {/* Sidebar Navigation Menus*/}
          <span className="heading">Main</span>
          <ul className="list-unstyled">
            <li>
              <a href="/Dashboard">
                {" "}
                <img
                  src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                  alt="Dashboard"
                  className="px-2"
                />{" "}
                Dashboard{" "}
              </a>
            </li>
            <li>
              <a href="/Profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                  alt="Profile"
                  className="px-2"
                />{" "}
                Profile{" "}
              </a>
            </li>
            <li className="active">
              <a href="/Notification">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                  alt="Notification"
                  className="px-2"
                />{" "}
                Notification{" "}
              </a>
            </li>
            <li>
              <a href="/Forms">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                  alt="update"
                  className="px-2"
                />{" "}
                Update Details{" "}
              </a>
            </li>
            <li>
              <a href="/PaymentDetails">
                {" "}
                <img
                  src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                  alt="Payment Details"
                  className="px-2"
                />{" "}
                Payment Details{" "}
              </a>
            </li>
            <li>
              <a href="/Settings">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                  alt="Settings"
                  className="px-2"
                />{" "}
                Settings{" "}
              </a>
            </li>
          </ul>
        </nav>
        <div className="content-inner">
          {/* Page Header*/}
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="no-margin-bottom">Notifications Dashboard</h2>
            </div>
          </header>
          {/* Updates Section  */}
          <section className="updates no-padding-top">
            <div className="container-fluid">
              <div className="row">
                {/* Daily Feeds */}
                <div className="col-lg-12 pt-5">
                  <div className="daily-feeds card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard7"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard7"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <a href="#/" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </a>
                          <a href="#/" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-header">
                      <h3 className="h4">Notifications</h3>
                    </div>
                    <div className="card-body no-padding">
                      {notifications.map((notification) => (
                        <div className="item" key={notification.review_id}>
                          <div className="feed d-flex justify-content-between">
                            <div className="feed-body d-flex justify-content-between">
                              <a href="#/" className="feed-profile">
                                <img
                                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                  alt="person"
                                  className="img-fluid rounded-circle"
                                />
                              </a>
                              <div className="content">
                                <h5>{notification.shopper.first_name}</h5>
                                <span></span>
                                <div className="full-date">
                                  <small>{notification.date_created}</small>
                                </div>
                              </div>
                            </div>
                            <div className="CTAs">
                              <Link
                                to={{
                                  pathname: `/NotificationDetails`,
                                  state: { id: notification.review_id },
                                }}
                                className="btn btn-xs btn-secondary">
                                Read more ...
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Page Footer*/}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Notification;
