import React, { useState } from "react";
import axios from "axios";

const Login = () => {
  const [data, setData] = useState({ email: "", password: "" });

  const submitHandler = async (e) => {
    e.preventDefault();

    if (data.email !== "" && data.password !== "") {
      axios
        .post(`${process.env.REACT_APP_Base_url}/admin/login/`, data)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 200) {
            sessionStorage.setItem("token", res.data.token);
            window.location.replace("/Dashboard");
          } else if (res.data.status === 400) {
            alert(res.data.message);
          } else {
            console.log("Redirect failed");
            alert(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="page login-page">
      <div className="container d-flex align-items-center">
        <div className="form-holder has-shadow">
          <div className="row">
            {/* Logo & Information Panel*/}
            <div className="col-lg-6">
              <div className="info d-flex align-items-center">
                <div className="content">
                  <div className="logo">
                    <h1>Rosmall Admin</h1>
                  </div>
                </div>
              </div>
            </div>
            {/* Form Panel    */}
            <div className="col-lg-6 bg-white">
              <div className="form d-flex align-items-center">
                <div className="content">
                  <form
                    className="form-validate"
                    autoComplete="on"
                    onSubmit={submitHandler}>
                    <div className="form-group">
                      <input
                        type="email"
                        required
                        className="input-material"
                        placeholder="Email"
                        onChange={(e) =>
                          setData({ ...data, email: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <input
                        placeholder="Password"
                        type="password"
                        required
                        className="input-material"
                        onChange={(e) =>
                          setData({ ...data, password: e.target.value })
                        }
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Login
                    </button>
                  </form>
                  <br />
                  <a href="/ForgotPassword" className="forgot-pass">
                    Forgot Password?
                  </a>
                  <br />
                  <small>Do not have an account? </small>
                  <a href="#." className="signup">
                    Signup
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrights text-center">
        <p>
          Owned by{" "}
          <a
            href="https://shopper.rosjob.tech/"
            target="_blank"
            rel="noreferrer"
            className="external">
            RosmallOnline
          </a>
        </p>
        <p />
      </div>
    </div>
  );
};

export default Login;
