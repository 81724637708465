import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

const NotificationDetails = (props) => {
  const [details, setDetails] = useState({
    messageDetails: null,
  });
  const [token, setToken] = useState("");
  const [id, setId] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    if (props.location.state) {
      setId(props.location.state.id);
    }
  }, [token, props.location.state]);

  useEffect(() => {
    if (id && token) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/company/rating_comment_details/`,
          {
            rating_comment_id: id,
          },
          {
            headers: {
              "X-COMPANY-USER-AUTH": token,
            },
          }
        )
        .then((res) => {
          setDetails((details) => {
            return { ...details, messageDetails: res.data.review_comment };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, token]);

  return (
    <div className="page">
      {/* Main Navbar*/}
      <Header />

      <div className="page-content d-flex align-items-stretch">
        {/* Side Navbar */}
        <nav className="side-navbar">
          {/* Sidebar Navigation Menus*/}
          <span className="heading">Main</span>
          <ul className="list-unstyled">
            <li>
              <a href="/Dashboard">
                {" "}
                <img
                  src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                  alt="Dashboard"
                  className="px-2"
                />{" "}
                Dashboard{" "}
              </a>
            </li>
            <li>
              <a href="/Profile">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                  alt="Profile"
                  className="px-2"
                />{" "}
                Profile{" "}
              </a>
            </li>
            <li className="active">
              <a href="/Notification">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                  alt="Notification"
                  className="px-2"
                />{" "}
                Notification{" "}
              </a>
            </li>
            <li>
              <a href="/Forms">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                  alt="update"
                  className="px-2"
                />{" "}
                Update Details{" "}
              </a>
            </li>
            <li>
              <a href="/PaymentDetails">
                {" "}
                <img
                  src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                  alt="Payment Details"
                  className="px-2"
                />{" "}
                Payment Details{" "}
              </a>
            </li>
            <li>
              <a href="/Settings">
                {" "}
                <img
                  src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                  alt="Settings"
                  className="px-2"
                />{" "}
                Settings{" "}
              </a>
            </li>
          </ul>
        </nav>

        <div className="content-inner">
          {/* Page Header*/}
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="no-margin-bottom">Notifications Dashboard</h2>
            </div>
          </header>

          {/* Updates Section  */}
          <section className="updates no-padding-top">
            <div className="container-fluid">
              <div className="row">
                {/* Daily Feeds */}
                <div className="col-lg-12 pt-5">
                  <div className="daily-feeds card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard7"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard7"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <Link to="#" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </Link>
                          <Link to="#" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-header">
                      <h3 className="h4">Notification</h3>
                    </div>
                    <div className="card-body no-padding">
                      {/* Item*/}

                      <div className="item">
                        <div className="feed d-flex justify-content-between">
                          <div className="feed-body d-flex justify-content-between">
                            <Link to="#" className="feed-profile">
                              <img
                                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                alt="person"
                                className="img-fluid rounded-circle"
                              />
                            </Link>
                            {details.messageDetails && (
                              <div className="content">
                                <h5 className="p-2">
                                  {" "}
                                  {
                                    details.messageDetails.shopper.first_name
                                  }{" "}
                                  {details.messageDetails.shopper.last_name}
                                </h5>
                                <span className="p-2">
                                  {details.messageDetails.shopper.location} ,{" "}
                                  {details.messageDetails.shopper.city}
                                </span>
                                <div className="full-date p-2">
                                  <b>{details.messageDetails.shopper.phone}</b>
                                </div>
                                <div className="full-date p-2">
                                  <b>
                                    Message:{" "}
                                    <span className="px-3">
                                      {details.messageDetails.comment}
                                    </span>
                                  </b>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Page Footer*/}

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default NotificationDetails;
