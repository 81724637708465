import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "ADMIN",
  initialState: {
    dashboard_info: {},
    cart_items: [],
    trend_list: [],
    hot_list: [],
    companies_list: [],
    subproducts_list: [],
    users_change: [],
  },
  reducers: {
    setDashboardInfo: (state, action) => {
      state.dashboard_info = action.payload;
    },
    updateCartList: (state, action) => {
      state.cart_items = action.payload;
    },
    updateTrendingList: (state, action) => {
      state.trend_list = action.payload;
    },
    updateHotList: (state, action) => {
      state.hot_list = action.payload;
    },
    updateCompaniesList: (state, action) => {
      state.companies_list = action.payload;
    },
    updateSubProductsList: (state, action) => {
      state.subproducts_list = action.payload;
    },
    updateUsersChange: (state, action) => {
      state.users_change = action.payload;
    },
  },
});

export const {
  setDashboardInfo,
  updateCartList,
  updateTrendingList,
  updateHotList,
  updateCompaniesList,
  updateSubProductsList,
  updateUsersChange,
} = adminSlice.actions;
export default adminSlice.reducer;
